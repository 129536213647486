export const environment = {
  name: 'staging',
  production: false,
  restricted: false,
  baseUrls: {
    papers: '//papersapp.com',
    readcube: '//staging.readcube.com',
    support: '//support.readcube.com',
    sync: 'https://sync-staging.readcube.com',
    services: 'https://services-staging.readcube.com',
    lambda: 'https://api-gateway.readcube.com',
    covid: 'https://covid19-staging.readcube.com'
  },
  injectableButtonUrl: 'https://content-staging.readcube.com/injectable-button-assets/readcube-subscription-button.min.js',
  clientName: 'covid-website',
  mixpanelApiKey: 'ab740815ce1a9615699f835e03499650',
  trackJsApp: 'covid-19-website-staging',
  trackJsToken: '4587af51bbb542afbbc9d93bf9567159',
  trackJsEnabled: true
};

import 'zone.js/plugins/zone-error';
